import './Diagram.css';
import '../../Components/Properties/Properties.css'
import './impl/joint.css';
import Diagram from './impl/diagram';
import History from "../History/History";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import $ from 'jquery';
import uuid from 'react-uuid'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import KeyIcon from '@mui/icons-material/Key';
import AbcIcon from '@mui/icons-material/Abc';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import HistoryIcon from '@mui/icons-material/HistoryTwoTone';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {bodyAttributes, labelAttributes, ToolTypes} from "./impl/diagram.common";
import {useCallHolder} from "react-callforward";
import Properties from "../Properties/Properties";
import Request from "../../utils/request";
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import beautify from 'js-beautify';
import BeautifyIcon from '@mui/icons-material/AutoFixHigh';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import {
    Alert,
    Button,
    Checkbox,
    Dialog,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Slide,
    TextareaAutosize,
    TextField,
    Tooltip
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
//import DateAdapter from '@mui/lab/AdapterMoment';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import "@uiw/react-textarea-code-editor/dist.css";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import SaveIcon from '@mui/icons-material/Save';
import Loading from "../Loading/Loading";
import {useNavigate, useParams} from 'react-router-dom'
import Box from "@mui/material/Box";

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import moment from "moment/moment";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import FolderTree from "../FolderTree/FolderTree";
import {
    getBreadCrumps,
    getSelectedFoler,
    getTimeZoneCfg,
    getToken,
    setBreadCrumps,
    setSelectedFoler
} from "../../utils/Common";
import {useHotkeys} from "react-hotkeys-hook";

import CodeMirror from '@uiw/react-codemirror';
import { loadLanguage } from '@uiw/codemirror-extensions-langs';
import {EditorView} from "@codemirror/view";

import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';


import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import DateIcon from '@mui/icons-material/CalendarMonth';
import ObjectIcon from '@mui/icons-material/DataObject';
import ArrayIcon from '@mui/icons-material/DataArray';
import BooleanIcon from '@mui/icons-material/CompareArrows';
import NumberIcon from '@mui/icons-material/Filter1';
import TextIcon from '@mui/icons-material/TextFormat';
import SearchIcon from '@mui/icons-material/Search';
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import MinimizeIcon from '@mui/icons-material/Minimize';
import _ from 'lodash';
import jwt_decode from "jwt-decode";
import Draggable from "react-draggable";
import Paper from '@mui/material/Paper';
import CommentBox from "../CommentBox/CommentBox";
import Fade from "@mui/material/Fade";
import {withStyles} from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";




let diagram = null;
// let _ = require('lodash');
//let isDeployable = false;
const DeployValidateStyle = withStyles({
    arrow: {
        fontSize: 20,
        "&::before": {
            backgroundColor: "rgba(243,62,62,0.8)",
        }
    },
    tooltip: {
        color: "white",
        backgroundColor: "rgba(243,62,62,0.8)",
        // height: "20px",
        textAlign: 'center',
        lineHeight: "20px"
    }
})(Tooltip);


function Diagramm({DiagramSaveClicked, DiagramDeployClicked, EmulateClicked, CallBackTreeData, UndoClicked, RedoClicked , CallBackDeployDisabled , MoreActionsClicked ,CallBackEmulateError, SelectedCompany, CompanyList}) {

    let company = '';

    const [OpenProperties, SetOpenProperties] = useState(false);
    const [OpenHistory, SetOpenHistory] = useState(false);
    const [DiagtamItemProperties, SetDiagtamItemProperties] = useState({ rules: { items: [] }, actions: [], table: { columns: [], rows: [] , dataExpression: null} });
    const [loading, setLoading] = useState(false);
    const [infoShow, setInfoShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoType, setInfoType] = useState('info');
    const [TreePopupOpen, setTreePopupOpen] = useState(false);
    const [DeployPopupOpen, setDeployPopupOpen] = useState(false);

    const [treeCode, setTreeCode] = useState('');
    const [treeTitle, setTreeTitle] = useState('');
    const [treeOpenDate, setTreeOpenDate] = useState(null);
    const [treeCloseDate, setTreeCloseDate] = useState(null);
    const [treeDescription, setTreeDescription] = useState('');
    const [treeVersion, setTreeVersion] = useState(0);
    const [treeChecksum, setTreeChecksum] = useState('');

    const [delay, setDelay] = useState(0);


    const [DeployTreeId, setDeployTreeId] = useState('');
    const [DeploytreeCode, setDeployTreeCode] = useState('');
    const [DeploytreeTitle, setDeployTreeTitle ] = useState('');
    const [DeploytreeOpenDate, setDeployTreeOpenDate] = useState(null);
    const [DeploytreeOpenTime, setDeployTreeOpenTime] = useState(null);

    // eslint-disable-next-line
    const [DeploytreeCloseDate, setDeployTreeCloseDate] = useState(null);
    const [DeploytreeDescription, setDeployTreeDescription] = useState('');

    const [selectedFolderId, setSelectedFolderId] = useState(company);
    const [, setSelectedFolderParentId] = useState('');

    const [selectedFolderName, setSelectedFolderName] = useState('root');
    // eslint-disable-next-line
    const [FoldersTreeData, setFoldersTreeData] = useState('');



    const [EmulateWindowOpen, setEmulateWindowOpen] = useState(false);
    const [debugValue, setDebugValue] = useState( JSON.stringify({}  , null, 4 ));

    const [typeName, setTypeName] = useState('');
    const [typeType, setTypeType] = useState('');
    const [dataFields, setDataFields] = useState([]);

    const [isDeployable, setIsDeployable] = useState(false);
    const [moreActionsAnchorEl, setmoreActionsAnchorEl] = useState(null);

    const [TitleError, setTitleError] = useState(false);
    const [CodeError, setCodeError] = useState(false);
    const [DeployTitleError, setDeployTitleError] = useState(false);
    const [DeployCodeError, setDeployCodeError] = useState(false);

    const [NodeSearchVisible , setNodeSearchVisible] = useState(false);
    const [nodeSearchValue, setNodeSearchValue] = useState('');

    const [storeEntity , setStoreEntity] = useState(false);
    const [ThrowIfNotFinished , setThrowIfNotFinished] = useState(true);

    const [deployStoreEntity , setDeployStoreEntity] = useState(false);
    const [deployThrowIfNotFinished , setDeployThrowIfNotFinished] = useState(true);

    const [errJson , setErrJson] = useState(false);

    const [nameTypeError, setNameTypeError] = useState('');

    const [DeployPopupReadonly , setDeployPopupReadonly] = useState(false);

    const [ExpandFolders , setExpandFolders] = useState([]);

    const [deployMetod, setDeployMetod] = useState('');
    const [changedDeployData, setChangedDeployData] = useState(null);

    const [TreeSaveEnabled, setTreeSaveEnabled] = useState(false);

    const [OpenDateError , setOpenDateError] = useState(false);
    const [DeployDateTimeValidate , setDeployDateTimeValidate] = useState({ errOpen: false , errText: '' });

    const moreActionsOpen = Boolean(moreActionsAnchorEl);
    const moreActionsHandleClick = (event) => {
        setmoreActionsAnchorEl(event.currentTarget);
    };
    const moreActionsHandleClose = () => {
        setmoreActionsAnchorEl(null);
    };

    const [jsonMode, setJsonMode] = useState(false);
    const [EmulateWindowCollapsed, setEmulateWindowCollapsed] = useState(false);
    const [EmulMinimized, setEmulMinimized] = useState(false);

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }


    // eslint-disable-next-line
    function PaperComponent(props) {

        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }




    const { code, id } = useParams();
    const navigate = useNavigate();


    const ShowInfo = (infoMessage , infoType) => {
        if (infoMessage && infoType){
            setInfoType(infoType);
            setInfoMessage(infoMessage);
            setInfoShow(true);
        }
    }
    const handleInfoClose = (event, reason) => {
        //console.log (reason)
        if (reason === 'clickaway') {
            return;
        }
        setInfoShow(false);
    };


    const userInfo = jwt_decode(getToken());
    useEffect(() => {
        if (userInfo){
            let clime = userInfo.security_claim;
            if (!Array.isArray(clime)) {
                clime = [clime];
            }
            setTreeSaveEnabled(clime.find(cl => cl === 'tree.draft.edit'));
        }
    }, [userInfo]);

    useEffect(()=>{
        CodeFieldValidate();
        TitleFieldValidate();
        // eslint-disable-next-line
    },[treeTitle, treeCode])


    useEffect(()=>{
        if (DeploytreeOpenTime) {
            if (DeploytreeOpenDate === null || !moment(DeploytreeOpenDate).isValid() ) {
                setOpenDateError(true) ;
                $('.DeployDate input').addClass('error');
            } else {
                setOpenDateError(false) ;
                $('.DeployDate input').removeClass('error');
            }
        } else {
            console.log (moment(DeploytreeOpenDate).isValid())
            if (DeploytreeOpenDate !== null && !moment(DeploytreeOpenDate).isValid()){
                setOpenDateError(true) ;
                $('.DeployDate input').addClass('error');
            } else {
                setOpenDateError(false) ;
                $('.DeployDate input').removeClass('error');
            }

        }
    },[DeploytreeOpenDate , DeploytreeOpenTime])


    useEffect(()=>{
        let BreadCrumps = getBreadCrumps();
        if (BreadCrumps){
            BreadCrumps.ExpandFolders = ExpandFolders;
            setBreadCrumps(BreadCrumps);
        }
        // eslint-disable-next-line
    },[ExpandFolders])


    let Model = {
        "code": "",  //textbox -> required = true
        "title": "", //textbox -> required = true
        "description": "", //textarea
        "openDate": null, //datepicker
        "closeDate": null, //datepicker
        "displayData": {},
        "enabled": true,
        "model": {
            StoreEntity: false,
            ThrowIfNotFinished: true,
            Nodes: []
        },
        "folderId": 0,
        "inputData": {},
        "dataFields":[],
        // "storeEntity": false,
        // "ThrowIfNotFinished": true,
        'checksum': ''
    }


    let DeployModel = {
        "id": null,
        "code": "",
        "title": "",
        "description": "",
        "openDate": null,
        "closeDate": null,
        "folderId": 0,
        "storeEntity" : false,
        "throwIfNotFinished": true,
        "checksum": ''
    }

    function findFolderById(obj, id) {
        if (obj.id === id) {
            return obj;
        }
       for (let i = 0; i < obj.childs.length; i++) {
            const child = obj.childs[i];
            const foundObj = findFolderById(child, id);
            if (foundObj) {
                return foundObj;
            }
        }
        return null;
    }

    function getParentIds(obj,targetObj, ids = []) {
        if (targetObj.parentId) {
            if (targetObj.id) {
                ids.push(targetObj.id);
            }
            const parentObj = findFolderById(obj, targetObj.parentId.toString());
            ids.push(parentObj.id);
            getParentIds(obj,parentObj, ids);
        } else {
            ids.push(targetObj.id);
        }
        return [...new Set(ids)];
    }

    const expandFolderArr = (companyID) => {
        Request(window._env_.REACT_APP_API_TREE_LIST + 'Folder/tree/' + companyID).get()
            .then((data) => {
                if (data && data.status !== 404) {
                    const targetObj = findFolderById(data, getSelectedFoler() ? getSelectedFoler().selectedID.toString() : companyID);

                    if (targetObj) {
                        setExpandFolders(getParentIds(data,targetObj));
                    }
                } else {
                    if (data.status === 404){
                        ShowInfo('Cannot get Folder list', 'error')
                    }
                    if (data.errors){
                        ShowInfo(data.errors[0].message, 'error')
                    }
                    if (data.message){
                        ShowInfo(data.message, 'warning')
                    }
                }
            }).catch(() => {
            ShowInfo('Cannot get Folder list', 'error')
        })
    }

    useEffect(() => {
        // eslint-disable-next-line
        diagram = new Diagram('paper');
        diagram.init();

        if (code){
            setLoading(true);
            let trId = id ?  "/" + id : '';
            Request(window._env_.REACT_APP_API_TREE_LIST + 'Tree/byid' + trId).get()
                .then((data) => {
                    setLoading(false);
                    if (data) {
                        // console.log (data)
                        diagram.fromJson(data.displayData);
                        diagram.triggerOnChange({target:'diagram', event: 'load'});

                        setTreeChecksum (data.checksum);
                        setSelectedFolderId(data.folderId);

                        setTreeCode(data.code);
                        setTreeTitle(data.title);
                        setTreeDescription(data.description);
                        setTreeOpenDate(moment(data.openDate, 'DD/MM/YYYY').toDate());
                        setTreeCloseDate(moment(data.closeDate, 'DD/MM/YYYY').toDate());

                        setDeployTreeId(data.id);
                        setDeployTreeCode(data.code);
                        setDeployTreeTitle(data.title);
                        setTreeVersion(data.version);
                        setDeployTreeDescription(data.description);
                       // setDeployTreeOpenDate(moment(data.openDate, 'DD/MM/YYYY').toDate());

                        setDeployTreeOpenDate(data.openDate ? moment(moment(new Date(moment(data.openDate, 'DD/MM/YYYY HH:mm:ss').valueOf() + (getTimeZoneCfg().offset * 60 * 1000))).format('DD/MM/YYYY HH:mm:ss'), 'DD/MM/YYYY HH:mm:ss').toDate() : null)
                        setDeployTreeOpenTime(data.openDate ? moment(moment(new Date(moment(data.openDate, 'DD/MM/YYYY HH:mm:ss').valueOf() + (getTimeZoneCfg().offset * 60 * 1000))).format('DD/MM/YYYY HH:mm:ss'), 'DD/MM/YYYY HH:mm:ss').toDate() : null)

                        setDeployTreeCloseDate(moment(data.closeDate, 'DD/MM/YYYY').toDate());

                        setStoreEntity(data.model.storeEntity);
                        setDeployStoreEntity(data.model.storeEntity);

                        setThrowIfNotFinished(data.model.throwIfNotFinished)
                        setDeployThrowIfNotFinished(data.model.throwIfNotFinished)


                        setDeployPopupReadonly(data.version > 0);


                        const FoldPath = data.folderPath.split('/')
                        const FolderName = FoldPath[FoldPath.length - 1]

                        setSelectedFoler({
                            "selectedID": data.folderId,
                            "selectedName": FolderName
                        })


                        setBreadCrumps({
                            "selectedFolderID": data.folderId,
                            "selectedFolderName": FolderName,
                            "treeCode" : data.code,
                            "treeID" :  data.id,
                            "companyId" : data.companyId,
                            "treeVersion" : data.version
                        })

                        expandFolderArr(data.companyId)

                        setDataFields(data.dataFields ? data.dataFields : []);

                        setDebugValue(JSON.stringify(data.inputData ? data.inputData : {}  , null, 4))
                        CallBackTreeData(data); // For header Info


                    } else {
                        if (data.errors){
                            ShowInfo(data.errors[0].message, 'error')
                        }
                        if (data.message){
                            ShowInfo(data.message, 'warning')
                        }
                    }
                }).catch(() => {
                setLoading(false);
                ShowInfo('Cannot Load the Diagram', 'error')
            })
        }

        diagram.onZoom = function(e) {
            $('#zoom-text').text(Math.round(e.scale * 100) + '%');
        };

        diagram.DiagramIsChange = function (e) {
            CallBackDeployDisabled(e);
            setIsDeployable (!e);

            // Detect changes without save and ask to save when leave page

            // if (e){
            //     const handleTabClose = event => {
            //         event.preventDefault();
            //         //console.log('beforeunload event triggered');
            //         return (event.returnValue = 'Are you sure you want to exit?');
            //     };
            //     window.addEventListener('beforeunload', handleTabClose);
            //     return () => {
            //         window.removeEventListener('beforeunload', handleTabClose);
            //     };
            // }

            // ----------------------------------------------------------------

        }


        $('#zoombox').on('click', '.zoom-btn', function(e) {
            e.preventDefault();
            let action = $(this).data("action");
            if (action === "zoomin") {
                diagram.zoom(1);
            } else if (action === "zoomout") {
                diagram.zoom(-1);
            } else if (action === "scalefit") {
                diagram.scaleContentToFit({ padding: { top: 20, right: 50, bottom: 70, left: 20 } });
            }

        });

        $('#history').on('click', '.history_button', function(e) {
            e.preventDefault();
            let action = $(this).data("action");
            if (action === "history") {
                SetOpenHistory(true);
            }
        })


        diagram.onElementDblClick = function(cellview, evt, x, y) {
            //let viewModel = cellview.model;
          //  console.log('double', cellview.model.id);
            let dataModel = $.extend(true, {},  cellview.model.prop('dm'));
       //     console.log('model', dataModel.id);
            // dataModel.rules.items.push({ value: 'Rule 1 loaded' });
            // dataModel.rules.items.push({ value: 'Rule 2 loaded' });
            // dataModel.rules.logic = 'or';
            //
            // dataModel.actions.push({ value: 'Action 1 loaded' });
            // dataModel.actions.push({ value: 'Action 2 loaded' });

            SetDiagtamItemProperties(dataModel);

            SetOpenProperties(true);

            // properties.show();
            // properties.bind(dataModel);

        };
        // eslint-disable-next-line
    },[code]);


    const onDragOver = (event) => {
        //event.stopPropagation();
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }

    const onDragEnter = (event) => {
        event.stopPropagation();
        event.dataTransfer.dropEffect = 'move';
    }

    const onDrop = (event) => {
        event.stopPropagation();
        // console.log("event", event);
        let elType = event.dataTransfer.getData("elementtype");
        let localPoint2 = diagram.paper.clientToLocalPoint(event.clientX, event.clientY);
        let newNode = diagram.newNode(localPoint2.x - 25, localPoint2.y - 25, elType, elType);
        let tableData = (elType === ToolTypes.LOOKUP ? { columns: [], rows: [] , dataExpression : null } : null) ;
        newNode.prop({ dm : { id: newNode.id, type: elType, actions: [], nodes: [], rules: {logic: 'and' , items: []}, Link: null, Title: elType, table: tableData }});
        diagram.addElement(newNode);
    }

    const dragStartHandler = (event) => {
        // console.log("start", event);
        let crt = event.target.cloneNode(true);

        crt.id = 'ekler';
        crt.style.cursor = 'grabbing';
        crt.style.backgroundColor = bodyAttributes[crt.style.backgroundColor = event.target.dataset.type].fill;
        crt.style.color = labelAttributes[crt.style.colorol = event.target.dataset.type].fill;

        crt.style.position = "absolute";
        crt.style.top = "-100px";
        crt.style.right = "-100px";
        document.body.appendChild(crt);
        event.dataTransfer.setDragImage(crt, 25, 25);
        event.dataTransfer.setData("elementtype", event.target.dataset.type);
    }

    const dragEndHandler = (e) => {
        document.getElementById('ekler').remove();
    }

    const TreePopupClose = () => {
        setTreePopupOpen(false);
    }
    const DeployPopupClose = () => {
        setDeployPopupOpen(false);
    }
    const EmulateWindowClose = () => {
        setJsonMode(false);
        setEmulateWindowOpen(false);
        setEmulateWindowCollapsed(false);
    }




    const treeSaveHandle = () => {
        let SaveDiagramJson = diagram.export();
      //  console.log ('diagram Export' , SaveDiagramJson.data)

        if (errJson) {
            ShowInfo('Json format error in Debug', 'error')
            return false;
        }

        let cellsModel = {
            StoreEntity: storeEntity,
            ThrowIfNotFinished : ThrowIfNotFinished,
            Nodes: SaveDiagramJson.data
        };

        Model.displayData = SaveDiagramJson.view;
        Model.model = cellsModel;
        Model.code = treeCode;
        Model.title = treeTitle;
        Model.openDate = moment(treeOpenDate).isValid() ? moment(treeOpenDate).format("DD/MM/YYYY"): null;
        Model.closeDate = moment(treeCloseDate).isValid() ? moment(treeCloseDate).format("DD/MM/YYYY") : null;
        Model.description = treeDescription;
        Model.folderId = selectedFolderId;
        Model.inputData = JSON.parse(debugValue);
        Model.dataFields = dataFields;
        // Model.storeEntity = storeEntity;
        // Model.ThrowIfNotFinished = ThrowIfNotFinished;
        Model.checksum = treeChecksum;
        console.log('saving model',Model );

        let res = {};
        Model.model.Nodes.forEach(function(v) {
            res[v.type] = (res[v.type] || 0) + 1;
        })


        if (res.Input > 1) {
            ShowInfo("More than one 'Input' node can not be added and saved", 'warning')
            return false;
        }

        if (TitleError || CodeError) {
            ShowInfo("Check 'Code' and 'Title' fields before save ", 'error')
        } else {
        TreePopupClose();
        setLoading(true);
        Request(window._env_.REACT_APP_API_TREE_LIST + 'Tree').post(Model)
            .then((data) => {
                setLoading(false);
                if (data) {
                    if (data && data.status !== 403) {
                        if (data.messageKey && data.messageKey === "gateway:invalid_user"){
                            const userInfo = jwt_decode(getToken());
                            ShowInfo(_.capitalize(userInfo.user_name) + " Invalid permission, you dont have access for this operation.", 'warning')
                            return;
                        }

                    }

                    if (data && data.status !== 400) {
                            ShowInfo(data.message, 'error')
                    }

                    if (data.id){
                        const FoldPath = data.folderPath.split('/')
                        const FolderName = FoldPath[FoldPath.length - 1]
                        setBreadCrumps({
                            "selectedFolderID": data.folderId,
                            "selectedFolderName": FolderName,
                            "treeCode" : data.code,
                            "treeID" :  data.id,
                            "companyId" : data.companyId,
                            "treeVersion" : data.version
                        })

                        expandFolderArr(data.companyId)


                        setTreeChecksum(data.checksum);
                        ShowInfo("💾 Successfuly Saved", 'success')
                        setIsDeployable(true);
                        CallBackDeployDisabled(false);
                        CallBackTreeData(data);
                        navigate('/diagram/' + data.code + '/' + data.id);

                    }
                    //removeSelectedFoler();
                } else {
                    if (data.errors){
                        ShowInfo(data.errors[0].message, 'error')
                    }
                    if (data.message){
                        ShowInfo(data.message, 'warning')
                    }
                }
            }).catch(() => {
            ShowInfo('Saving Problem', 'warning');
            TreePopupClose();
            setLoading(false);
        })
    }}

    const recursiveSearch = (obj, searchKey, results = []) => {
        const r = results;
        Object.keys(obj).forEach(key => {
            const value = obj[key];
            if(key === searchKey && typeof value !== 'object'){
                r.push(value);
            }else if(typeof value === 'object'){
                recursiveSearch(value, searchKey, r);
            }
        });
        return r;
    };

    const storeEntityChange = (e) => {
        setStoreEntity(e.target.checked);
    }

    const ThrowIfNotFinishedChange = (e) => {
        setThrowIfNotFinished(e.target.checked);
    }

    const DeployStoreEntityChange = (e) => {
        setDeployStoreEntity(e.target.checked);
    }

    const DeployThrowIfNotFinishedChange = (e) => {
        setDeployThrowIfNotFinished(e.target.checked);
    }


    const taskSaveHandle = () => {
        //debugValue
        //treeCode

        if (errJson) {
            ShowInfo('Json format error in Debug', 'error')
            return;
        }

        if (!isDeployable && !EmulateWindowCollapsed){
          //  console.log ('popoxutyun ka , save pdi arvi nor run');
            setConfirmDialog(true)

        } else {
            if (!debugValue){
                ShowInfo('Input data can not be empty, insert Input Data and try again', 'info')
                return;
            }

            let TaskBody = {
                "row": {
                    "TreeCode": treeCode,
                    "TreeVersion": treeVersion,
                    "CompanyId": parseFloat(SelectedCompany)
                },
                "entity": JSON.parse(debugValue)
            }
            setLoading(true);
            Request(window._env_.REACT_APP_API_TREE_LIST + 'Task').post(TaskBody)
                .then((data) => {
                        setLoading(false);
                        setJsonMode(false);
                        diagram.unhighlightAll();
                        if (data && !Object.hasOwn(data, 'exceptionType')) {
                            if (data && Object.hasOwn(data, 'message')) {
                                ShowInfo('Can\'t complate this operation. You dont have "Task Manager" Claim.','error')
                            } else {
                                let jsonBodyReturned = JSON.stringify(data.entity, null, 4);
                                setDebugValue(jsonBodyReturned);
                            }
                            setJsonMode(true);
                            if (data.row){
                                diagram.highlightPath(data.row.path);
                            }
                           // console.log ('Response Debug - ',data);
                        } else {
                            let NodeID = '';
                            if (data.exceptionType === "NodeProcessorException"){
                                NodeID = data.info;
                                diagram.showElement(NodeID , 'red');
                                let errors = recursiveSearch(data, 'message');
                                ShowInfo(errors.join('|'),'error')
                            } else {
                                ShowInfo(data.message,'error')
                            }
                           // console.log ('data',data);
                           // ShowInfo('Runing Debug process problem','warning')
                        }
                    }
                )
        }

    }


    const GetCompanyNameByCompanyId = (id) => {
        let CompanyName;
        CompanyList.forEach((company) => {
            if (company.id === id){
               CompanyName =  company.name;
            }
        })
        return CompanyName;
    }


    useCallHolder(() => {
        setSelectedFolderName((getSelectedFoler() ? getSelectedFoler().selectedName : GetCompanyNameByCompanyId(SelectedCompany) ))
        setSelectedFolderId((getSelectedFoler() ? getSelectedFoler().selectedID : SelectedCompany ))
        setTreePopupOpen(true);
    }, DiagramSaveClicked);

    useCallHolder((e) => {
        setSelectedFolderName((getSelectedFoler() ? getSelectedFoler().selectedName : GetCompanyNameByCompanyId(SelectedCompany) ))
        setSelectedFolderId((getSelectedFoler() ? getSelectedFoler().selectedID : SelectedCompany ))
        setDeployPopupOpen(true);
        setDeployMetod(e);
    }, DiagramDeployClicked);

    useCallHolder(() => {
        if (errJson || !debugValue) setJsonMode(false);
        if (!errJson && debugValue && debugValue !== '{}') setJsonMode(true);
        setEmulateWindowOpen(true);
    }, EmulateClicked);

    useCallHolder(() => {
        let data = diagram.undo();
        if (data && data.action !=='load') {
            ShowInfo("Undo ↶ action '" + data.action + "'", 'info')
        }
    }, UndoClicked);

    useCallHolder(() => {
        let data = diagram.redo();
        if (data && data.action !=='load') {
            ShowInfo("Redo ↷ action '" + data.action + "'", 'info')
        }
    }, RedoClicked);

    useCallHolder((e) => {
        moreActionsHandleClick(e)
    }, MoreActionsClicked);



    useHotkeys('ctrl+z', (e) =>{
            e.preventDefault();
            let data =  diagram.undo();
            if (data && data.action !=='load') {
                ShowInfo("Undo ↶ action '" + data.action + "'", 'info')
            }
        }
    );
    useHotkeys('ctrl+y', (e) =>{
            e.preventDefault();
            let data =  diagram.redo();
            if (data && data.action !=='load') {
                ShowInfo("Redo ↷ action '" + data.action + "'", 'info')
            }
        }
    );
    useHotkeys('ctrl+r', (e) =>{
            e.preventDefault();
            setEmulateWindowOpen(true)
        }
    );
    useHotkeys('ctrl+s', (e) =>{
            e.preventDefault();
            if (TreeSaveEnabled) {
                if (treeCode){
                    treeSaveHandle();
                } else {
                    setTreePopupOpen(true);
                }
            }
        },[treeCode,Model,company]
    );
    // useHotkeys('ctrl+d', (e) =>{
    //       e.preventDefault();
    //       console.log ('ddddd',isDeployable)
    //       if (isDeployable)  setDeployPopupOpen(true);
    //     }
    // );
    useHotkeys('ctrl+h', (e) => {
            e.preventDefault();
            SetOpenHistory(prevState => {
                return !prevState
            })
         }
    );

    // delete for mac and windows
    useHotkeys('delete', (e) =>{
           e.preventDefault();
           diagram.deleteSelected();
        }
    );

    useHotkeys('backspace', (e) =>{
            e.preventDefault();
            diagram.deleteSelected();
        }
    );
    //********** delete for mac and windows

    // Copy and past with ctrl and com for mac and win
    useHotkeys('ctrl+c', (e) =>{
            e.preventDefault();
            diagram.copySelection();
        }
    );

    useHotkeys('cmd+c', (e) =>{
            e.preventDefault();
            diagram.copySelection();
        }
    );

    useHotkeys('ctrl+v', (e) =>{
            e.preventDefault();
            diagram.pasteSelection();
        }
    );

    useHotkeys('cmd+v', (e) =>{
            e.preventDefault();
            diagram.pasteSelection();
        }
    );
    // ***** Copy and past with ctrl and com for mac and win

    // Search in Node property's by F7 key
    useHotkeys("F7", (e) => {
        e.preventDefault();
        setNodeSearchVisible(NodeSearchVisible => !NodeSearchVisible );
    });
    useHotkeys("ctrl+f", (e) => {
        e.preventDefault();
        setNodeSearchVisible(NodeSearchVisible => !NodeSearchVisible );
    });

    useHotkeys("Escape", (e) => {
        e.preventDefault();
        NodeSearchClose();
    });

    const JsBtfyoptions = { indent_size: 2, space_in_empty_paren: true }




        //------------------------------------

    //---- Delay search process
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const prevnodeSearchValue = usePrevious(nodeSearchValue);
    useEffect(()=>{
        if(prevnodeSearchValue !== nodeSearchValue) {
            const timeoutId = setTimeout(() =>{
                NodeSearchProcess();
            }, delay);
            return () => {
                clearTimeout(timeoutId)
                if (delay === 0) {setDelay(1500)}
            };
        } else {
            NodeSearchProcess();
        }
        // eslint-disable-next-line
    },[nodeSearchValue])
    // End of delay search process


    const NodeSearchProcess = () => {
        diagram.unhighlightAll();
        const DispData = diagram.toJson();
        let highlightArr = [];
        DispData.cells.forEach(function (cell){
            if (cell.type !=="Link") {
                if (cell.dm.Title) {
                    if (nodeSearchValue.length >= 3){
                        if (cell.dm.Title.toLowerCase().indexOf(nodeSearchValue.toLowerCase()) !== -1){
                            highlightArr.push(cell.id)
                        }
                    } else {
                        if (nodeSearchValue.toLowerCase() === cell.dm.Title.toLowerCase()){
                            highlightArr.push(cell.id)
                        }
                    }
                }

                if (cell.dm.actions.length > 0){
                    cell.dm.actions.forEach(function (action){
                        if (nodeSearchValue.length >= 3){
                            if (action.value.toLowerCase().indexOf(nodeSearchValue.toLowerCase()) !== -1){
                                highlightArr.push(cell.id)
                            }
                        } else {
                            if (nodeSearchValue.toLowerCase() === action.value.toLowerCase()){
                                highlightArr.push(cell.id)
                            }
                        }
                    });
                }
                if (cell.dm.rules.items.length > 0){
                    cell.dm.rules.items.forEach(function (rule){
                        if (nodeSearchValue.length >= 3){
                            if (rule.value.toLowerCase().indexOf(nodeSearchValue.toLowerCase()) !== -1){
                                highlightArr.push(cell.id)
                            }
                        } else {
                            if (nodeSearchValue.toLowerCase() === rule.value.toLowerCase()){
                                highlightArr.push(cell.id)
                            }
                        }
                    });
                }
            }
        });
           highlightArr = highlightArr.filter(onlyUnique);
           diagram.showSearch(highlightArr, 'search')
    }



    const DeployProcess = (comment , changedDeployData = null)=> {
       // console.log (DeploytreeOpenDate)
        let mm = moment(DeploytreeOpenDate).isValid() && DeploytreeOpenTime ? moment(DeploytreeOpenDate).format("DD/MM/YYYY") + " " + moment(DeploytreeOpenTime).format("HH:mm:ss") : null;
        let isBefore = moment(mm, 'DD/MM/YYYY HH:mm:ss').isBefore(moment());

        if (OpenDateError) {
            setDeployDateTimeValidate({errOpen: true , errText: 'Invalid Date - Field is Required and needs to contain a valid date'});
            return ;
        }  else if (isBefore) {
            setDeployDateTimeValidate({errOpen: true , errText: 'Date Time can not be before today'});
            return ;
        }

        let Uri = null;
        DeployModel.id = DeployTreeId;
        DeployModel.code = DeploytreeCode;
        DeployModel.title = DeploytreeTitle;
        DeployModel.openDate = moment(DeploytreeOpenDate).isValid() && mm ? moment(new Date(moment(mm, 'DD/MM/YYYY HH:mm:ss').valueOf() - (getTimeZoneCfg().offset * 60 * 1000))).format('DD/MM/YYYY HH:mm:ss') : null;
        DeployModel.closeDate = null; //moment(DeploytreeCloseDate).isValid() ? moment(DeploytreeCloseDate).format("DD/MM/YYYY") : null;
        DeployModel.description = DeploytreeDescription;
        DeployModel.folderId = selectedFolderId;
        DeployModel.storeEntity = deployStoreEntity;
        DeployModel.throwIfNotFinished = deployThrowIfNotFinished;
        DeployModel.checksum = treeChecksum;


        if (deployMetod && deployMetod === "Deploy") {
            Uri = 'Tree/deploy';
            // console.log ('gnuma Deployi flowov')
            // console.log('CommentText', comment ? comment : "comment chka");

        } else if (deployMetod && deployMetod === "DeployRequest") {
            setDeployPopupOpen(false);
            setCommentBox(!comment);
            if (comment){
                Uri = 'deployrequest';
                DeployModel.notes = comment;
                delete (DeployModel.id);
                DeployModel.treeId = DeployTreeId;
                DeployModel.autoCancelChecksum = "";
                // console.log ('gnuma Requesti flowov')
                // console.log('CommentText', comment ? comment : "comment chka");
            }

        }



        if (errJson) {
            ShowInfo('Json format error in Debug', 'error')
            return false;
        }

        // console.log ('Uri',Uri);
        // console.log ('Deploymodel', DeployModel)




        let diag_nodes = diagram.export();

        if (diag_nodes.data.length < 1 && diag_nodes.view.cells.length < 1){
            ShowInfo(" Can not deploy empty Tree", 'warning')
            return false;
        }

        if (DeployTitleError || DeployCodeError) {
            ShowInfo("Check 'Code' and 'Title' fields before Deploy ", 'error')
        } else {

            if (Uri){
                DeployPopupClose();
                setLoading(true);
                Request(window._env_.REACT_APP_API_TREE_LIST + Uri).post(changedDeployData ? changedDeployData : DeployModel)
                    .then((data) => {
                        setLoading(false);
                        if (data) {
                            if (data && data.status !== 403) {
                                if (data.messageKey && data.messageKey === "gateway:invalid_user"){
                                    const userInfo = jwt_decode(getToken());
                                    ShowInfo(_.capitalize(userInfo.user_name) + " Invalid permission, you dont have access for this operation.", 'warning')
                                    return;
                                }
                            }

                            if (deployMetod === "DeployRequest" && data.key && data.key === "deploy_request_exists") {
                                 DeployModel.autoCancelChecksum = data.info;
                                 DeployModel.notes = comment;
                                 setDeployRequestConfirmDialog(true);
                                 setChangedDeployData(DeployModel);
                                 return false;
                            }


                            if (data && data.status !== 400) {
                                ShowInfo(data.message, 'warning')
                            }

                            if (data.id){
                                ShowInfo(deployMetod && deployMetod === "DeployRequest" ? "➦ Deploy Request Successfuly Created" : "➦ Successfuly Deployed", 'success')
                            }

                            // console.log ('Saved Data - ',data )

                            //navigate('/diagram/' + data.code + '/' + data.id);
                            //CallBackTreeData(data)
                            //removeSelectedFoler();
                        } else {
                            if (data.errors){
                                ShowInfo(data.errors[0].message, 'error')
                            }
                            if (data.message){
                                ShowInfo(data.message, 'warning')
                            }
                        }
                    }).catch(() => {
                    ShowInfo('Deploy Problem', 'warning');
                    DeployPopupClose();
                    setLoading(false);
                })
            }


   }
}



    const ReturnedProperties = (e) => {
        console.log('ReturnedProperties', e);
        e.rules.items = e.rules.items.filter(item => item.value && item.value !=='' && item.value !==null );
        e.actions = e.actions.filter(action => action.value && action.value !=='' && action.value !==null );
        let cell = diagram.getCell(e.id);
        // console.log ('e.rules.items' , e.rules.items)
        // console.log("Cell" , cell);
        // console.log("Cell rules items" , cell.attributes.dm.rules.items);

        // cell.prop('dm') hamematel ei het
        if ( !_.isEqual(cell.prop('dm'), e)) {
            cell.attributes.dm = e;
            cell.prop({ dm : e });
            cell.attr('label/text',e.Title);

            setTimeout(function (){
                diagram.triggerOnChange({target:'node', event: 'node data change'});
            },0);
        }
    }

    function SlideTransition(props) {
        return <Slide {...props} direction="up" />;
    }

    const selectedID = (selectedID) => {
        setSelectedFolderId(selectedID)
    }
    const selectedName = (selectedName) => {
        setSelectedFolderName(selectedName)
    }
    const SelectedParentId = (SelectedParentId) => {
        setSelectedFolderParentId(SelectedParentId)
    }
    const FolderTreeData = (TreeData) => {
        setFoldersTreeData (TreeData)
    }

    // const codeMirrorExtentions = () =>{
    //     return !OpenProperties ? [EditorView.lineWrapping,javascript()] : [EditorView.lineWrapping,langs.json()]
    // }

    const OpenHistoryClose = (e) => {
        SetOpenHistory(e)
    }

    const inputRef = React.useRef();


    function AddTypesItem(item) {
        if (typeName && typeType) {
            if (!dataFields.some(existingItem => existingItem.name.toLowerCase().trim() === item.name.toLowerCase().trim())) {
                let newdataFields = [...dataFields, item];
                setDataFields(newdataFields);
                setTypeName('');
                setTypeType('');
                setNameTypeError('');
                inputRef.current.focus();
                diagram.triggerOnChange({target: 'TypeDeclaration', event: 'add type'});
            } else {
                setNameTypeError('You have already have this variable in youyr list');
            }
        }
    }

    function deleteTypesItem(index) {
        const newdataFields = [...dataFields];
        newdataFields.splice(index, 1);
        setDataFields(newdataFields);
        diagram.triggerOnChange({target:'TypeDeclaration', event: 'remove type'});
    }

    const [confirmDialog, setConfirmDialog] = useState(false);
    const [DeployRequestConfirmDialog, setDeployRequestConfirmDialog] = useState(false);
    const [commentBox, setCommentBox] = useState(false);
    const [commentKeepTxt, setCommentKeepTxt] = useState('');



    const onConfirmDialogClose = (result) => {
        setConfirmDialog(false);
        if (result) {
            //console.log ('Save and Run');
            setTimeout(function (){
                if (treeCode){
                    treeSaveHandle();
                } else {
                    setTreePopupOpen(true);
                }

            },0)

        }
    };

    const onDeployRequestConfirmDialogClose = (result) => {
        setDeployRequestConfirmDialog(false);
        if (result) {
            setTimeout(function (){
                DeployProcess(commentKeepTxt ,changedDeployData);
            },0)

        }
    };




    const onCommentBoxClose = () => {
        setCommentBox(false);
    };

    const CommentText = (result) => {

        setCommentBox(false);
        if (result){
            setCommentKeepTxt(result);
            DeployProcess(result);
        }
    }

    const onAddTypeCallback = (e) => {
        let tmpDataFields = [];
        tmpDataFields = dataFields;
        tmpDataFields.push(e);
        setDataFields(tmpDataFields);
    }

    const exportDiagramData = (data) => {
        
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(
                {
                    Diagram : data,
                    debugData : {debugValue},
                    debugTypes: {dataFields}
                }
            )
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = (treeCode ? treeCode : 'new') + "-diagram.json";

        link.click();
    };

    const ALPHA_NUMERIC_DASH_UNDERLINE_REGEX = /^[a-zA-Z0-9_-]+$/;

    const CodeFieldValidate = (e) =>{
        setCodeError(!treeCode);
    }
    const TitleFieldValidate = (e) =>{
        setTitleError(!treeTitle);
    }
    const DeployCodeFieldValidate = (e) =>{
        setDeployCodeError(!DeploytreeCode);
    }
    const DeployTitleFieldValidate = (e) =>{
        setDeployTitleError(!DeploytreeTitle);
    }

    const NodeSearchClose = () =>{
        diagram.unhighlightAll();
        setNodeSearchVisible(false);
    }


    // QuickRUn Functional
    const debugMinimize = () =>{
        $('#EmulateWindow .MuiDialog-paperWidthSm').css('min-width' , '100%').css('max-width' , '100%')
        setEmulMinimized(true)
        setTimeout(()=>{
            let EmulWindow = $('#EmulateWindow .MuiDialog-paperWidthSm')
            let EmulwindowWith = EmulWindow.width();
            EmulWindow.css('min-width' , EmulwindowWith).css('max-width' , EmulwindowWith)
            setEmulMinimized(false)
            EmulateWindowClose();
            setEmulateWindowCollapsed(true)
        },500)
    }
    useEffect(()=>{
        if (isDeployable && EmulateWindowCollapsed){
            taskSaveHandle();
        }
        // eslint-disable-next-line
    },[isDeployable])
    const quickRunClick = () => {
        if (!isDeployable) {
            treeSaveHandle()
        } else {
            taskSaveHandle();
        }
    }
    // QuickRun Functional


    // Import diagram JSON file
    let importEl = document.querySelector("#diagram-file-input");
    if (importEl){
        importEl.addEventListener('change', function() {
            let all_files = this.files;
            if (all_files.length === 0) {
                ShowInfo('Error : No diagram file selected', 'error')
                return;
            }

            // file selected
            let file = all_files[0];
            // files types allowed
            let allowed_types = ['application/json'];
            if (allowed_types.indexOf(file.type) === -1) {
                ShowInfo('Error : Incorrect diagram file type it\'s need to be JSON format', 'error')
                return;
            }

            // Max 10 MB allowed
            let max_size_allowed = 10 * 1024 * 1024
            if (file.size > max_size_allowed) {
                ShowInfo('Error : Exceeded size 10MB', 'error')
                return;
            }


            let reader = new FileReader();

            // file reading finished successfully
            reader.addEventListener('load', function(e) {
                let diagramJson = JSON.parse(e.target.result);
                console.log (diagramJson);
                if (diagramJson.hasOwnProperty('Diagram') && diagramJson.hasOwnProperty('debugData') && diagramJson.hasOwnProperty('debugTypes') ) {
                    console.log ('OK')
                     diagram.fromJson(diagramJson.Diagram.view);
                     setDebugValue(diagramJson.debugData.debugValue);
                     setDataFields(diagramJson.debugTypes.dataFields);
                } else {
                    ShowInfo('Not Decision Maker Json format', 'error')
                }

              //  diagram.fromJson(JSON.parse(diagramJson).view)


            });

            // file reading failed
            reader.addEventListener('error', function() {
                ShowInfo('Error : Failed to read diagram file', 'error')
            });

            reader.readAsText(file);
        })
    }


    // const cmOptions = {
    //     theme: "default",
    //     height: "auto",
    //     viewportMargin: Infinity,
    //     mode: {
    //         name: "javascript",
    //         json: true,
    //         statementIndent: 1
    //     },
    //     // extraKeys:{"Shift-Tab":autoFormatSelection},
    //     lineNumbers: true,
    //     lineWrapping: true,
    //     indentWithTabs: false,
    //     tabSize: 2
    // };

    //********

    function tryParseJSONObject (jsonString){
        try {
            let o = JSON.parse(jsonString);
            if (o && typeof o === "object") {
                return o;
            }
        }
        catch (e) {  }
        return false;
    }

    const JsonValidate = (value) => {
        //console.log ('k',value)
        if (value){
            if (tryParseJSONObject(value)) {
                setErrJson(false);
                CallBackEmulateError(false)
            } else {
                setErrJson(true);
                CallBackEmulateError(true)
            }
        } else {
            setErrJson(false);
            CallBackEmulateError(false)
        }

    }



    return (
        <div>
            {loading &&
                <Loading fullscreen />
            }
            <Properties
                OpenProperties={OpenProperties}
                OpenPropertiesClose={SetOpenProperties}
                DiagtamItemProperties={DiagtamItemProperties}
                ReturnedProperties={ReturnedProperties}
                DebugValue = {debugValue}
                DataFields = {dataFields}
                onAddTypeCallback = { onAddTypeCallback }

            />
            <History  OpenHistory={OpenHistory} OpenHistoryClose={OpenHistoryClose} treeCode={treeCode} treeId = {id} />

            <div id="document-wrapper">
                {/*<div id="document-header-ghost"></div>*/}
                {/*<a href={url} className="m-button" onClick={exporClick} id="export-btn">Export JSON</a>*/}
                <div id="section-body">
                    <ul id="toolbox">
                        <li draggable onDragStart={dragStartHandler} onDragEnd={dragEndHandler} className="toolbox-item" data-type="Input"><CircleOutlinedIcon style={{backgroundColor: '#aada00',color:"white"}}> </CircleOutlinedIcon><span>Input</span></li>
                        <li draggable onDragStart={dragStartHandler} onDragEnd={dragEndHandler} className="toolbox-item" data-type="Switch"><CircleOutlinedIcon style={{backgroundColor: '#ffda00',color:"white"}}> </CircleOutlinedIcon><span>Switch</span></li>
                        <li draggable onDragStart={dragStartHandler} onDragEnd={dragEndHandler} className="toolbox-item" data-type="Actions"><CircleOutlinedIcon style={{backgroundColor: '#ff7070',color:"white"}}> </CircleOutlinedIcon><span>Actions</span></li>
                        <li draggable onDragStart={dragStartHandler} onDragEnd={dragEndHandler} className="toolbox-item" data-type="Rule"><CircleOutlinedIcon style={{backgroundColor: '#649de3',color:"white"}}> </CircleOutlinedIcon><span>Rule</span></li>
                        <li draggable onDragStart={dragStartHandler} onDragEnd={dragEndHandler} className="toolbox-item" data-type="Link"><CircleOutlinedIcon style={{backgroundColor: '#cf6ee7',color:"white"}}> </CircleOutlinedIcon><span>Link</span></li>
                        {/*<li draggable onDragStart={dragStartHandler} onDragEnd={dragEndHandler} className="toolbox-item" data-type="Parallel"><CircleOutlinedIcon style={{backgroundColor: '#c72121',color:"white"}}> </CircleOutlinedIcon><span>Parallel</span></li>*/}
                        <li draggable onDragStart={dragStartHandler} onDragEnd={dragEndHandler} className="toolbox-item" data-type="Lookup"><CircleOutlinedIcon style={{backgroundColor: 'rgb(9 206 255)',color:"white"}}> </CircleOutlinedIcon><span>Lookup</span></li>
                        <li draggable onDragStart={dragStartHandler} onDragEnd={dragEndHandler} className="toolbox-item" data-type="Output"><CircleOutlinedIcon style={{backgroundColor: '#d3d3d3',color:"white"}}> </CircleOutlinedIcon><span>Output</span></li>

                    </ul>


                    <div id="paper-wrapper">
                        <div id="paper"
                             onDragEnter={onDragEnter}
                             onDragOver={onDragOver}
                             onDrop={onDrop}
                        ></div>
                        <div id='diagramFooter' className='diagramFooter'>
                            <span className='footerLabel'>Checksum:</span>
                            <span className='footerText'>{treeChecksum ? treeChecksum : 'not available'}</span>
                        </div>
                    </div>
                    <div id="properties-panel-wrap">
                        <span id="properties-panel-close"><i className="fa fa-times" aria-hidden="true"></i></span>
                        <div id="properties-panel">
                        </div>
                    </div>
                </div>
                <div id="zoombox">
                    <a href='/#' className="zoom-btn plus" data-action="zoomin"><AddIcon /></a>
                    <span id="zoom-text">100%</span>
                    <a href='/#' className="zoom-btn minus" data-action="zoomout"><RemoveIcon /></a>
                    <a href='/#' className="zoom-btn reset" data-action="scalefit"><FullscreenIcon /></a>
                </div>
                <Tooltip title="Version History ( Ctrl+H )" placement="left">
                    <div id="history">
                        <a href='/#' className="history_button" data-action="history"><HistoryIcon /></a>
                    </div>
                </Tooltip>

                {EmulateWindowCollapsed &&
                    <Tooltip title="Quick Run" placement="left">
                        <div id="quickRun" onClick={()=>{ quickRunClick() }}>
                           <PlayCircleFilledWhiteIcon />
                        </div>
                    </Tooltip>
                }

                <div id="NodeSearch" className={`NodeSearch ${NodeSearchVisible? " visible" : ""}`} >
                    <TextField
                        // error = {CodeError}
                        className='nodeSearch_field'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon onClick={()=> { NodeSearchProcess() }} />
                                </InputAdornment>
                            )
                        }}
                        id="outlined-NodeSearch"
                        value={nodeSearchValue}
                        onChange={(e)=> {setNodeSearchValue(e.target.value)}}
                        autoFocus
                        onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                                setDelay(0);
                                NodeSearchProcess()
                            } else if (event.key === "Escape") {
                                setDelay(0);
                                NodeSearchClose();
                            }
                        }}
                        inputRef={(input) => {
                            if(input != null) {
                                input.focus();
                            }
                        }}
                    />
                    <CloseIcon onClick={()=> { NodeSearchClose() }} className='NodeSearch_close_button'/>
                </div>

            </div>



            <Snackbar open={infoShow}  autoHideDuration={infoType !== 'error' ? 5000 : null} onClose={handleInfoClose}
                      TransitionComponent={Fade}
                      key={SlideTransition.name}
            >
                <Alert onClose={handleInfoClose} severity={infoType} sx={{ width: '100%' }}>
                    {
                        infoMessage.split("|").map((message,index) =>
                       <span style={{display: 'block'}} key={index}>{infoMessage.split("|").length > 1 ? index + " - ": ''} {message} </span>
                       )
                    }
                </Alert>
            </Snackbar>

            <Dialog PaperProps={{ className: 'TreePopup' }} onClose={TreePopupClose} open={TreePopupOpen}>
                <div style={{position: 'releative'}}>
                    <DialogTitle>Tree</DialogTitle>
                    <CloseIcon onClick={()=> { TreePopupClose() }} className='TreePopup_close_button'/>
                </div>
                <Divider />
                <div className="TreePopupContent">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '35ch',marginTop: '15px' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            error = {CodeError}
                            className={CodeError ? 'txtErr':''}
                            id="outlined-treeCode"
                            label="Code*"
                            value={treeCode}
                            onChange={(e)=> {setTreeCode(e.target.value)}}
                            onBlur={(e)=>{CodeFieldValidate()}}
                            onKeyDown={(event) => {
                                if (!ALPHA_NUMERIC_DASH_UNDERLINE_REGEX.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}

                        />
                        <TextField
                            error = {TitleError}
                            className={TitleError ? 'txtErr':''}
                            id="outlined-treeTitle"
                            label="Title*"
                            value={treeTitle}
                            onChange={(e)=> {setTreeTitle(e.target.value)}}
                            onBlur={(e)=>{TitleFieldValidate()}}
                        />

                        <div style ={{display: 'none'}}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Open Date"
                                inputFormat="DD/MM/YYYY"
                                value={treeOpenDate}
                                onChange={(e)=>{setTreeOpenDate(e)}}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            <DatePicker
                                label="Close Date"
                                inputFormat="DD/MM/YYYY"
                                value={treeCloseDate}
                                onChange={(e)=>{setTreeCloseDate(e)}}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        </div>


                        <div className='TreeSelectWrapper'>
                            <TextField
                                disabled
                                className='SelectedFolder'
                                placeholder='Select Folder'
                                value={selectedFolderName}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FolderOpenIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton className='openTreeList'><ArrowDropDownCircleOutlinedIcon /></IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                onClick={(e)=>{
                                    let TreeListElement = document.querySelector('.TreeSelect');
                                    if (TreeListElement.classList.contains('displayShow')){
                                        TreeListElement.classList.remove('displayShow')
                                    } else {
                                        TreeListElement.classList.add('displayShow')
                                    }
                                    // document.querySelector('.TreeSelect')
                                }}

                            />
                            <div className="TreeSelect">
                                <div className='folderList'>
                                    <FolderTree selectedID={selectedID} SelectedParentId = {SelectedParentId} selectedName={selectedName} FolderTreeData={FolderTreeData} selectedCompany={SelectedCompany}/>
                                </div>
                            </div>
                        </div>

                        <FormControl sx={{ m: 3 }}  className='StoreEntytyCheck' component="fieldset" variant="standard">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={storeEntity} onChange={storeEntityChange}
                                    sx={{
                                        color: '#a8a3db',
                                        '&.Mui-checked': {
                                            color: '#a8a3db',
                                        },
                                    }}
                                />
                            }
                            label="Store Entity"
                        />
                        </FormControl>


                        <FormControl sx={{ m: 3 }}  className='ThrowIfNotFinishedCheck' component="fieldset" variant="standard">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={ThrowIfNotFinished} onChange={ThrowIfNotFinishedChange}
                                        sx={{
                                            color: '#a8a3db',
                                            '&.Mui-checked': {
                                                color: '#a8a3db',
                                            },
                                        }}
                                    />
                                }
                                label="Exception when output node not reached"
                            />
                        </FormControl>

                        <TextareaAutosize
                            aria-label="minimum height"
                            minRows={8}
                            placeholder="Description"
                            //style={}
                            value = {treeDescription}
                            onChange={e => setTreeDescription(e.target.value)}
                        />


                        <Button onClick={()=>{treeSaveHandle()}}><SaveIcon className="icons_margin" /> Save</Button>
                    </Box>

                </div>
            </Dialog>


            <Dialog PaperProps={{ className: 'TreePopup' }} onClose={DeployPopupClose} open={DeployPopupOpen}>
                <div style={{position: 'releative'}}>
                    <DialogTitle>{` ${deployMetod && deployMetod === "DeployRequest" ? 'Deploy Request' : 'Deploy'}`}</DialogTitle>
                    <CloseIcon onClick={()=> { DeployPopupClose() }} className='DeployPopup_close_button'/>
                </div>
                <Divider />
                <div className="DeployPopupContent">
                    <Box
                        // style={DeployPopupReadonly ? {opacity : "0.6" } : {}}
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '35ch',marginTop: '15px' },
                        }}
                        noValidate
                        autoComplete="off"
                    >

                        <TextField
                            disabled = {DeployPopupReadonly}
                            error = {DeployCodeError}
                            className={DeployCodeError ? 'txtErr':''}
                            id="outlined-Deploy-treeCode"
                            label="Code*"
                            value={DeploytreeCode}
                            onChange={(e)=> {setDeployTreeCode(e.target.value)}}
                            onBlur={(e)=>{DeployCodeFieldValidate()}}
                            onKeyDown={(event) => {
                                if (!ALPHA_NUMERIC_DASH_UNDERLINE_REGEX.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}

                        />
                        <TextField
                            // disabled = {DeployPopupReadonly}
                            error = {DeployTitleError}
                            className={DeployTitleError ? 'txtErr':''}
                            id="outlined-Deploy-treeTitle"
                            label="Title*"
                            value={DeploytreeTitle}
                            onChange={(e)=> {setDeployTreeTitle(e.target.value)}}
                            onBlur={(e)=>{DeployTitleFieldValidate()}}
                        />
                            <div className="DeployDate">
                                <LocalizationProvider  dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        className='OpenDate'
                                        label="Open Date"
                                        inputFormat="DD/MM/YYYY"
                                        disablePast
                                        value={DeploytreeOpenDate}
                                        onChange={(e)=>{
                                            setDeployTreeOpenDate(e);
                                            setOpenDateError(false);
                                            setDeployDateTimeValidate({});
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />

                                    <TimePicker
                                        className="TimePic"
                                        label="Open Time"
                                        value={DeploytreeOpenTime}
                                        // disabled = {DeployPopupReadonly}
                                        ampm={false}
                                        onChange={(e) => {
                                            setDeployTreeOpenTime(e)
                                            setOpenDateError(false);
                                            setDeployDateTimeValidate({});
                                        }}
                                        PopperProps={{
                                            sx: {
                                                '&.MuiPickersPopper-root': {borderRadius : '5px'},
                                                '.MuiPickersArrowSwitcher-root' : {display: 'none'},
                                                '.MuiClockPointer-root': {backgroundColor: '#837cb7'},
                                                '.MuiClockPointer-thumb':  {backgroundColor: '#837cb7', borderColor: '#837cb7'},
                                                '.MuiClock-pin': {backgroundColor: '#837cb7'},
                                                '.MuiClock-clock' : {borderRadius : '3%', backgroundColor: 'rgb(79 79 79 / 7%)'},
                                                '.MuiCalendarOrClockPicker-root > div' : {width : "unset"},
                                                '.MuiClockPicker-root' : {width : "unset"}

                                            }}}
                                        minutesStep={1}
                                        renderInput={(params) => (<TextField {...params} />
                                        )}
                                         />
                                    {/*<DatePicker*/}
                                    {/*    disabled = {DeployPopupReadonly}*/}
                                    {/*    label="Close Date"*/}
                                    {/*    inputFormat="DD/MM/YYYY"*/}
                                    {/*    value={DeploytreeCloseDate}*/}
                                    {/*    onChange={(e)=>{setDeployTreeCloseDate(e)}}*/}
                                    {/*    renderInput={(params) => <TextField {...params} />}*/}
                                    {/*/>*/}
                                </LocalizationProvider>

                            </div>
                        <span className='dates-hint-label'>*Leave empty Date and Time fields for Deploy Now</span>

                        <div className='TreeSelectWrapper'>
                            <TextField
                                disabled
                                className='SelectedFolder'
                                placeholder='Select Folder'
                                value={selectedFolderName}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FolderOpenIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton className='openTreeList'><ArrowDropDownCircleOutlinedIcon /></IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                // onClick={(e)=>{
                                //     if (!DeployPopupReadonly){
                                //         let TreeListElement = document.querySelector('.TreeSelect');
                                //         if (TreeListElement.classList.contains('displayShow')){
                                //             TreeListElement.classList.remove('displayShow')
                                //         } else {
                                //             TreeListElement.classList.add('displayShow')
                                //         }
                                //         // document.querySelector('.TreeSelect')
                                //     }
                                //
                                // }}

                            />
                            <div className="TreeSelect">
                                <div className='folderList'>
                                    <FolderTree selectedID={selectedID} SelectedParentId = {SelectedParentId} selectedName={selectedName} FolderTreeData={FolderTreeData} selectedCompany={SelectedCompany}/>
                                </div>
                            </div>
                        </div>

                        <FormControl sx={{ m: 3 }}  className='StoreEntytyCheck' component="fieldset" variant="standard">
                            <FormControlLabel
                                // disabled = {DeployPopupReadonly}
                                control={
                                    <Checkbox
                                        checked={deployStoreEntity} onChange={DeployStoreEntityChange}
                                        sx={{
                                            color: '#a8a3db',
                                            '&.Mui-checked': {
                                                color: '#a8a3db',
                                            },
                                        }}
                                    />
                                }
                                label="Store Entity"
                            />
                        </FormControl>


                        <FormControl sx={{ m: 3 }}  className='ThrowIfNotFinishedCheck' component="fieldset" variant="standard">
                            <FormControlLabel
                                // disabled = {DeployPopupReadonly}
                                control={
                                    <Checkbox
                                        checked={deployThrowIfNotFinished} onChange={DeployThrowIfNotFinishedChange}
                                        sx={{
                                            color: '#a8a3db',
                                            '&.Mui-checked': {
                                                color: '#a8a3db',
                                            },
                                        }}
                                    />
                                }
                                label="Exception when output node not reached"
                            />
                        </FormControl>


                        <TextareaAutosize
                            // disabled = {DeployPopupReadonly}
                            aria-label="minimum height"
                            minRows={8}
                            placeholder="Description"
                            //style={}
                            value = {DeploytreeDescription}
                            onChange={e => setDeployTreeDescription(e.target.value)}
                        />



                    </Box>
                    <DeployValidateStyle
                        open={DeployDateTimeValidate.errOpen}
                        title={DeployDateTimeValidate.errOpen ? DeployDateTimeValidate.errText : ''}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 0 }}
                        arrow
                    >
                    <Button onClick={()=>{DeployProcess()}} className="DeployProcess_button" ><DoubleArrowIcon className="icons_margin" />
                        {` ${deployMetod && deployMetod === "DeployRequest" ? 'Deploy Request' : 'Deploy'}`}
                    </Button>
                    </DeployValidateStyle>
                </div>

            </Dialog>


            <Dialog PaperProps= {{className: `EmulateWindow ${EmulMinimized ? 'collapsed' : ''}`}} id="EmulateWindow" onClose={EmulateWindowClose} open={EmulateWindowOpen}
                    // ${EmulateWindowCollapsed ? 'collapsed' : ''}
                    // PaperComponent={PaperComponent}
                    // aria-labelledby="draggable-dialog-title"
            >
                <div style={{position: 'releative'}}>
                    <DialogTitle
                        // style={{ cursor: 'grab' }} id="draggable-dialog-title"
                    > Run</DialogTitle>
                    <MinimizeIcon onClick={()=> { debugMinimize() }} className='EmulateWindow_minimize_button'/>
                    <CloseIcon onClick={()=> { EmulateWindowClose() }} className='EmulateWindow_close_button'/>
                </div>
                <Divider />
                <DialogContent style={{padding: "8px 4px"}}>
                <div className="EmulateWindowContent">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '33ch',marginTop: '15px' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div className="debugWrapper">
                            <div className='debugerHeader'></div>
                            <div className={`jsonCode ${errJson? " errJson" : ""}`}>
                                {errJson && <div className="invalidJsonMessage">Invalid JSON format</div>}

                                {!jsonMode &&
                                    <div className='burifyJson'>
                                        <IconButton title="Beautify JSON" onClick={() => {
                                            setDebugValue(beautify(debugValue, JsBtfyoptions))
                                        }}><BeautifyIcon/></IconButton>
                                    </div>
                                }

                                <div className={`editorMode ${jsonMode? "JsonMode" : "plainMode"}`}>
                                    <IconButton title="Editor mode" onClick={()=> {
                                        if (!errJson) setJsonMode(!jsonMode);
                                        setDebugValue(beautify(debugValue, JsBtfyoptions))
                                    }}><AppRegistrationIcon /></IconButton>
                                    <span style={{marginLeft: '13px' , verticalAlign: 'middle'}}>{jsonMode ? 'JSON mode' : 'Plain mode'}</span>
                                </div>

                                { !jsonMode ?
                                    <CodeMirror
                                        extensions={[EditorView.lineWrapping,loadLanguage('json')]}
                                        // options = {cmOptions}
                                        value={debugValue}
                                        onChange={(value, viewUpdate) => {
                                            setDebugValue(value);
                                            JsonValidate(value);

                                            if (!value) {setDebugValue(JSON.stringify({}  , null, 4 )); setErrJson(false)}
                                        }}
                                        autoFocus = {true}
                                        indentWithTab = {true}
                                        height="100%"
                                        width="258px"
                                        style={{
                                            fontSize: 14,
                                            backgroundColor: "#f4f5f6",
                                            border: 'thin solid #efeeee',
                                            width: 'inherit',
                                            height: '554px',
                                            marginTop: '38px',
                                            borderRadius: '6px',
                                            resize: 'none',
                                            overflowY: 'auto',
                                            fontFamily: 'Consolas,ui-monospace,SFMono-Regular,SF Mono,Liberation Mono,Menlo,monospace',
                                        }}
                                    />
                                    :

                                        <Editor
                                            value={JSON.parse(debugValue)}
                                            onChange={(value) => {
                                                setDebugValue(JSON.stringify(value))
                                            }}
                                            navigationBar={false}
                                            statusBar={false}
                                            sortObjectKeys={false}
                                        />
                                }

                            </div>
                            <div className="typeList">
                                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    {/*<div className="typeHdeader">Type Declaration</div>*/}
                                    <div className="typeControls">
                                        <div className='name'>
                                            <DeployValidateStyle
                                                placement='top'
                                                open={!!nameTypeError}
                                                title={nameTypeError ? nameTypeError : ''}
                                                TransitionComponent={Fade}
                                                TransitionProps={{ timeout: 0 }}
                                                arrow
                                            >
                                            <TextField
                                                error = {!!nameTypeError}
                                                className={nameTypeError ? 'txtErr':''}
                                                placeholder='Propery Name'
                                                value={typeName}
                                                inputRef={inputRef}
                                                onChange={(e)=> {
                                                    setNameTypeError('');
                                                    setTypeName(e.target.value);
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <KeyIcon />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                            </DeployValidateStyle>
                                        </div>
                                        <div className='type'>
                                            <FormControl sx={{ m: 1 }}  size="small">
                                                <Select
                                                    labelId="TypeSelect"
                                                    id="select-type"
                                                    value={typeType}
                                                    onChange={(e)=>setTypeType(e.target.value)}
                                                    startAdornment={
                                                        <InputAdornment  position="start">
                                                            <AbcIcon />
                                                        </InputAdornment>
                                                    }
                                                    placeholder="Type"
                                                    displayEmpty={true}
                                                    renderValue={value => value?.length ? value : 'Select Type...'}
                                                >
                                                    <MenuItem value="Number"><NumberIcon/> Number</MenuItem>
                                                    <MenuItem value="String"><TextIcon /> String</MenuItem>
                                                    <MenuItem value="Boolean"><BooleanIcon /> Boolean</MenuItem>
                                                    <MenuItem value="Date"><DateIcon /> Date</MenuItem>
                                                    <MenuItem value="Object"><ObjectIcon /> Object</MenuItem>
                                                    <MenuItem value="Array"><ArrayIcon /> Array</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <IconButton onClick={()=> {AddTypesItem({name : typeName , type: typeType})}} className="addTypeButton" size="small" sx={{ ml: 2 }} > <AddIcon />   </IconButton>
                                    </div>
                                        <div className="typesTable">
                                            {dataFields.length > 0 && dataFields.map((dataField,index) =>
                                                <div className="typesWrap" key={Math.random()} style={{display: "flex",background: index % 2 === 0 ? "rgb(233 234 236)" : "rgb(240 239 246)"}}>
                                                    <Tooltip key={Math.random()} title={dataField.name}  placement="left">
                                                    <span style={{flex: "100%", padding: "10px", borderRight: "1px solid silver"}}>{dataField.name}</span>
                                                    </Tooltip>
                                                        <span style={{flex: "50%", padding: "10px"}}>{dataField.type}</span>
                                                    <DeleteIcon onClick={()=>{deleteTypesItem(index)}} className="TypeDelButton" />
                                                </div>
                                            )}

                                        </div>
                                </Box>
                            </div>
                        </div>


                    </Box>

                </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{
                        taskSaveHandle()
                    }}><PlayCircleFilledWhiteIcon className="icons_margin" /> Start</Button>
                </DialogActions>

            </Dialog>


            <Menu
                anchorEl={moreActionsAnchorEl}
                id="moreAction-menu"
                open={moreActionsOpen}
                onClose={moreActionsHandleClose}
                onClick={moreActionsHandleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

                <MenuItem onClick={()=>{setNodeSearchVisible(true)}}>
                        <span className="moreActionMenuItemText">
                            	<label htmlFor="diagram-node-search" id="diagram-node-search-label">
                         <ListItemIcon>
                            <SearchIcon fontSize="small" />
                        </ListItemIcon>
                                    <span>Search</span>
                                </label>

                        </span>
                </MenuItem>


                    <MenuItem>

                        <span className="moreActionMenuItemText">
                            	<label htmlFor="diagram-file-input" id="diagram-file-input-label">
                         <ListItemIcon>
                            <FileUploadIcon fontSize="small" />
                        </ListItemIcon>
                                    <span>Import</span>
                                </label>
	                            <input type="file" id="diagram-file-input" accept="text/json"/>
                        </span>
                    </MenuItem>

                    <MenuItem onClick={()=>{exportDiagramData(diagram.export())}}>
                        <ListItemIcon>
                            <FileDownloadIcon fontSize="small" />
                        </ListItemIcon>
                        <span className="moreActionMenuItemText">Export</span>
                    </MenuItem>

            </Menu>

            <CommentBox
                open={commentBox}
                required
                requiredText="Comment for Deploy Request is Required"
                onClose={(e) => { onCommentBoxClose(e)}}
                CommentText ={(e) => { CommentText(e)}}
            />

            <ConfirmDialog open={DeployRequestConfirmDialog} onClose={(e) => { onDeployRequestConfirmDialogClose(e)  }}
                           title="Warning"
                           text= {["Deploy request already exist for this tree", <Divider key={uuid()} style={{ marginBottom: '10px' }} light />, "Do you want to cancel existing request and create the new one?"]}
            />

            <ConfirmDialog open={confirmDialog} onClose={(e) => { onConfirmDialogClose(e)  }}
                           title="Warning"
                           text= {["You have any changes and can not be run without Tree save ...", <Divider key={uuid()} style={{ marginBottom: '10px' }} light />, "Save changes ?"]}
            />
        </div>
    );
}


export default Diagramm;