import './Login.css';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {useEffect, useState} from 'react';
import  Request from "../../utils/request";
import Loading from "../Loading/Loading";
import {setLanguage, setUserSession} from "../../utils/Common";
import Snackbar from '@mui/material/Snackbar';
import Logo from "../../Assets/logo.png";
import {Alert, Link, Slide, Tooltip} from "@mui/material";
import dictionary from "../../utils/dictionary";
import * as React from "react";
import imglogo from '../../Assets/imglogo.png'
import Fade from "@mui/material/Fade";
import {withStyles} from "@material-ui/core/styles";
import secureLocalStorage from "react-secure-storage";

const ValidateStyle = withStyles({
    arrow: {
        fontSize: 20,
        "&::before": {
            backgroundColor: "rgba(243,62,62,0.8)",
        }
    },
    tooltip: {
        color: "white",
        backgroundColor: "rgba(243,62,62,0.8)",
        // height: "20px",
        textAlign: 'center',
        lineHeight: "20px"
    }
})(Tooltip);
const Login = () => {

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [smallscreen, setSmallscreen] = useState(false);
    const [infoShow, setInfoShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoType, setInfoType] = useState('info');
    const [recoverEmail, setRecoverEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [recoverSent, setRecoverSent] = useState(false);


    const ShowInfo = (infoMessage , infoType) => {
        if (infoMessage && infoType){
            setInfoType(infoType);
            setInfoMessage(infoMessage);
            setInfoShow(true);
        }
    }
    const EmailFieldValidate = () =>{
        setEmailError(!recoverEmail ? 'Email Required' : '');
        // eslint-disable-next-line
        let mailformat = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(recoverEmail && !recoverEmail.match(mailformat)) {
            setEmailError('Incorrect E-mail format');
        }
    }
    const handleLogin = (username, password, refreshToken = "null") => {
    setLoading(true);
    const data = { username, password, refreshToken };
        Request(window._env_.REACT_APP_API_TREE_LIST + 'user/login').post(data,null,true)
            .then((data) => {
                setLoading(false);
                if (data) {
                    console.log (data);
                    const token = data.accessToken
                        if (token){
                            secureLocalStorage.setItem ('psObj', {usr: username , psw: password})
                            setUserSession(token , {})
                            ShowInfo('Successfuly Loged In', 'success')
                            window.location.reload(false);
                        } else {
                            if (data.errors){
                                ShowInfo(data.errors[0].message, 'error')
                            }else {
                                if (data.message){
                                    ShowInfo(data.message, 'warning')
                                }
                            }
                           
                        }
                }
            })
    }
    const handleRecoverPassword = (recoverEmail) =>{
        EmailFieldValidate();
      setTimeout(()=>{
          if (recoverEmail !== '' && emailError === '') {
              setLoading(true);
              Request(window._env_.REACT_APP_API_TREE_LIST + 'user/password-reset').post(
                  {
                      "email" : recoverEmail
                  },null,true
              )
                  .then((data) => {
                      setLoading(false);
                      if (data === false){
                          ShowInfo('Problem with Reset password','error')
                          return;
                      }
                      if (data){
                          if (data.errors){
                              ShowInfo(data.errors[0].message, 'error')
                              return;
                          }
                          if (data.message){
                              ShowInfo(data.message,'warning')
                              return;
                          }
                          if (data){
                              ShowInfo('Password Reset link successfuly sent to E-mail', 'success');
                              setRecoverSent(true);
                          }
                      }
                  })
          } else {
              ShowInfo('Problem with Reset password', 'error')
          }
      },400)

    }
    const handleInfoClose = (event, reason) => {
       // console.log (reason)
        if (reason === 'clickaway') {
            return;
        }
        setInfoShow(false);
    };
    const changeLang = (e,lang) => {
        e.preventDefault();
        setLanguage(lang);
        window.location.reload(false);
    }
    useEffect(() => {
        //Unsuported Device
        let mediaQueryList = window.matchMedia('(max-width: 484px)');
        setSmallscreen (mediaQueryList.matches)
        mediaQueryList.addEventListener("change", (e) => {
            setSmallscreen(e.matches)
        });
    },[]);
    function SlideTransition(props) {
        return <Slide {...props} direction="up" />;
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleLogin(userName, password);
        }
    }
    const handleKeyDownRecoverPassword = (e) => {
        if (e.key === 'Enter') {
            handleRecoverPassword(recoverEmail);
        }
    }


    return (
        <div className={`StyledBoxWrapper ${smallscreen ? "unSupportScreen" : ""}`}>
            {smallscreen && <div className='unsuportedContainer'>
                <div className='unsuportLogo'>
                    <img src={Logo} alt='logo'/>
                </div>
                <div className='unsuportText'>
                    Unsuported Device
                </div>
            </div>
            }
            {loading &&
              <Loading fullscreen />
            }
            <div className="StyledBoxAuth">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                <Box>
                    <span className="StyledBoxAuth">{dictionary("login_login")}</span>
                    <span className="StyledHeading">
                        Welcome to <div className="StyledSpan">LogiX</div>
                    </span>
                </Box>
                <span className="StyledInfo">
                   {dictionary("login_title")}
                </span>
                <form className="StyledForm">
                    <Box sx={{ position: 'relative', marginBottom: '30px' }}>
                        <TextField
                            label={dictionary("login_username")}
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            onKeyDown={(e)=>handleKeyDown(e)}
                            placeholder="Enter Your Username"
                            type={'text'}
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ position: 'relative' }}>
                        <TextField
                            label={dictionary("login_password")}
                            value={password}
                            onKeyDown={(e)=>handleKeyDown(e)}
                            onChange={(e) => setPassword(e.target.value)}
                            type={'password'}
                            placeholder="Enter Your Password"
                            fullWidth
                        />
                    </Box>

                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => handleLogin(userName, password)}
                    >
                        {dictionary("login_login")} <ArrowRightAltIcon sx={{ marginLeft: '5px' }} />
                    </Button>

                    <Link href="#" onClick={()=>{
                        document.querySelector(".flip-card-inner").style.transform = "rotateY(180deg)";
                    }} className='recoverPassLnk'>{dictionary("recover_recover")}</Link>
                </form>
                   </div>
                        <div className="flip-card-back">

                            <Box>
                                <span className="StyledBoxRecover">{dictionary("recover_recover")}</span>
                                <span className="StyledHeading">
                        Welcome to <div className="StyledSpan">LogiX</div>
                    </span>
                            </Box>
                            <span className="StyledInfo">
                   {dictionary("recover_title")}
                </span>
                            <form className="StyledForm">
                                <Box sx={{ position: 'relative', marginBottom: '30px' }}>
                                    <ValidateStyle
                                        placement='bottom'
                                        open={!!emailError}
                                        title={emailError ? emailError : ''}
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 0 }}
                                        arrow
                                    >
                                    <TextField
                                        disabled={recoverSent}
                                        label={dictionary("recover_email")}
                                        value={recoverEmail || ''}
                                        onChange={(e) => setRecoverEmail(e.target.value)}
                                        onKeyDown={(e)=>handleKeyDownRecoverPassword(e)}
                                        type={'text'}
                                        fullWidth
                                        error = {!!emailError}
                                        onBlur={()=>{EmailFieldValidate()}}
                                        className={emailError ? 'txtErr':''}
                                    />
                                    </ValidateStyle>
                                </Box>
                                <Button
                                    disabled={recoverSent}
                                    fullWidth
                                    variant="contained"
                                    onClick={() => handleRecoverPassword(recoverEmail)}
                                >
                                    {dictionary("recover_recover")} <ArrowRightAltIcon sx={{ marginLeft: '5px' }} />
                                </Button>

                                <Link href="#" onClick={()=>{
                                    setRecoverEmail('');
                                    setEmailError('');
                                    setRecoverSent(false);
                                    document.querySelector(".flip-card-inner").style.transform = "rotateY(0deg)";
                                }} className='recoverPassLnk'>{dictionary("login_login")}</Link>
                            </form>
                        </div>
                    </div>
            </div>
            <Box sx={{ backgroundColor: '#334050',display: 'flex', paddingBottom: '25px', justifyContent: 'center', }}>
                <div className="imgbox" style={{position: "absolute" , top: "40%"}}>
                    <img src={imglogo} alt="Decision Maker" style={{width : "200px"}}/>
                    <span className="loginLogoTitle">Decision Maker</span>
                </div>
                <Link href="#" onClick={(e) => changeLang (e,'en')}>English</Link>
                <Link href="#" onClick={(e) => changeLang (e,'ru')}>Русский</Link>
                <Link href="#" onClick={(e) => changeLang (e,'hy')}>Հայերեն</Link>
            </Box>
            <Snackbar open={infoShow} autoHideDuration={5000} onClose={handleInfoClose}
                      TransitionComponent={Fade}
                      key={SlideTransition.name}
            >
                <Alert onClose={handleInfoClose} severity={infoType} sx={{ width: '100%' }}>
                    {infoMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Login;
