import './App.css';
import Diagramm from "../Diagram/Diagram";
import TreeList from '../Trees/Trees'
import Header from "../Header/Header";
import {useCallForward} from "react-callforward";
import Login from "../Login/Login";
import UserActivate from "../UserActivate/UserActivate";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
    getSelectedCompanyCfg,
    getToken,
    removeSelectedFoler,
    removeUserSession, setDRBadgeCount,
    setSelectedCompanyCfg
} from "../../utils/Common";
import React, {useMemo, useState} from "react";
import Request from "../../utils/request";
import jwt_decode from "jwt-decode";
import Snackbar from "@mui/material/Snackbar";
import {Alert, Slide} from "@mui/material";
import DeployRequest from "../DeployRequest/DeployRequest";
import CompareTrees from "../Compare/Compare";
import Fade from "@mui/material/Fade";
import UserManagement from "../UserManagement/UserManagement";
import _ from "lodash";


function App(props) {
    const [DiagramSaveClick, DiagramSaveClicked] = useCallForward();
    const [DiagramDeployClick, DiagramDeployClicked] = useCallForward();
    const [EmulateClick, EmulateClicked] = useCallForward();
    const [UndoClick, UndoClicked] = useCallForward();
    const [RedoClick, RedoClicked] = useCallForward();
    const [moreActionsClick, MoreActionsClicked] = useCallForward();

    const [DeployManageActionsClick, DeployManageActionsClicked] = useCallForward();

    const {lang} = props.lang;
    const [token , setToken] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [treeData, setTreeData] = useState(null);
    const [DeployDisabled,setDeployDisabled] = useState(true);

    const [DeployManageActionsStat,setDeployManageActionsStat] = useState(null);


    const [EmulateErr , setEmulateErr] = useState(false);

    const [infoShow, setInfoShow] = useState(false);
    const [infoMessage, setInfoMessage] = useState('');
    const [infoType, setInfoType] = useState('info');

    const [CompanyList, setCompanyList] = useState([]);
    const [selectedCompany, setselectedCompany] = useState(0);

    const [unlock, setUnlock] = useState(false);


    window.addEventListener('storage', function(event) {
            if (event.key === 'getSessionStorage') {
                localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
                localStorage.removeItem('sessionStorage');
            } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
                let data = JSON.parse(event.newValue);
                let key;
                for (key in data) {
                    sessionStorage.setItem(key, data[key]);
                    setRefresh (true)
                }
            } else if (event.key === 'logout' && event.newValue==='true') {
                localStorage.removeItem('sessionStorage');
                removeUserSession();
                localStorage.removeItem('logout');
                window.location.reload(false);
            }
        }, false);

    if (!sessionStorage.length) {
        localStorage.setItem('getSessionStorage', 'foobar');
        localStorage.removeItem('getSessionStorage', 'foobar');
    };



    const getCompanyList = (token) => {
        let userInfo = null;
        if (token) {userInfo = jwt_decode(token)}
        Request(window._env_.REACT_APP_API_TREE_LIST + 'Folder/tree/0').get()
            .then((data) => {
                if (data && data.status !== 404) {
                    if (data.messageKey && data.messageKey === "gateway:invalid_user"){
                        const userInfo = jwt_decode(getToken());
                        ShowInfo(_.capitalize(userInfo.user_name) + " Invalid permission, you dont have access for this operation.", 'warning')
                        return;
                    }


                    setCompanyList(data.childs);

                    let SelectedCompany = getSelectedCompanyCfg();
                    if (!SelectedCompany) {
                        setSelectedCompanyCfg({[userInfo.sub]: data.childs[0].id})
                    } else {
                        if (!SelectedCompany.hasOwnProperty(userInfo.sub)){
                            SelectedCompany[userInfo.sub] = data.childs[0].id
                            setSelectedCompanyCfg(SelectedCompany);
                        }
                    }
                   // console.log (getSelectedCompanyCfg())
                } else {
                    if (data.status === 404){
                        ShowInfo('Cannot get Company List', 'error')
                        setUnlock(true);
                        return;
                    }
                    if (data.errors){
                        ShowInfo(data.errors[0].message, 'error')
                        setUnlock(true);
                    }
                    if (data.message){
                        ShowInfo(data.message, 'warning')
                        setUnlock(true);
                    }
                }
            }).then(()=>{
            let SelectedCompany = getSelectedCompanyCfg();
            if (SelectedCompany.hasOwnProperty(userInfo.sub)){
                setselectedCompany(SelectedCompany[userInfo.sub])
            }
        })
            .then(()=>{
                setUnlock(true);
            })
            .catch(() => {
                ShowInfo('Cannot get Company List', 'error')
                setUnlock(true);
            })

    }

    const getDRBadgeCount = () => {
        if (selectedCompany !==0){
            Request(window._env_.REACT_APP_API_TREE_LIST + 'DeployRequest/Unread?companyId=' + selectedCompany).get()
                .then((data) => {
                        if (data) {
                           // setDrBadgeCount(drBadgeCount + data.result+1)
                            setDRBadgeCount(data.result)
                        } else {
                            if (!data){
                                ShowInfo('Maybe you have problems accessing the server', 'error')
                            }
                            if (data.errors){
                                ShowInfo(data.errors[0].message, 'error')
                            }
                            if (data.message){
                                ShowInfo(data.message, 'warning')
                            }
                        }
                    }
                ).catch(() => {
                console.log('Cannot Load Deploy Request Badge Count')
            })

        }
    }



    useMemo(() => {
        setToken(getToken());
        removeSelectedFoler();
        // eslint-disable-next-line
    }, [refresh]);



    if (!token) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={ <Login lang={lang}/> } />
                    <Route path="*" element={ <Login lang={lang}/> } />
                    <Route path="/user/activate/:userid/" element={<UserActivate />} />
                    <Route path="/user/resetpassword/:userid/" element={<UserActivate />} />
                </Routes>
            </BrowserRouter>
        );
    }

    if (!unlock) {
        // const userInfo = jwt_decode(getToken());
        // let clime = userInfo.security_claim;
        // if (!Array.isArray(clime)) {
        //     clime = [clime];
        // }
        // if (clime.find(cl => cl === 'folder.read')) {
           getCompanyList(token);
        // }
        setDRBadgeCount(0)
        setInterval(() => {
            getDRBadgeCount();
        }, 180000);
        getDRBadgeCount();
    }



    const CallBackTreeData = (treeData) =>{
        if (treeData) {
            setTreeData(treeData)
        }
    }

    const DeployManageActionsStatus = (data) =>{
        setDeployManageActionsStat(data);
    }

    const CallBackDeployDisabled = (data) =>{
            setDeployDisabled(data);
    }

    const CallBackEmulateError = (data) =>{
        setEmulateErr(data);
    }


    const ShowInfo = (infoMessage , infoType) => {
        if (infoMessage && infoType) {
            setInfoType(infoType);
            setInfoMessage(infoMessage);
            setInfoShow(true);
        }
    }

    const handleInfoClose = (event, reason) => {
        //console.log (reason)
        if (reason === 'clickaway') {
            return;
        }
        setInfoShow(false);
    };

    function SlideTransition(props) {
        return <Slide {...props} direction="up" />;
    }



    const Main = () => {
            return (
                <div>
                    <Header
                        lang={lang}
                        EmulateClick={EmulateClick}
                        DiagramSaveClick={DiagramSaveClick}
                        DiagramDeployClick={DiagramDeployClick}
                        treeData={treeData}
                        UndoClick={UndoClick}
                        RedoClick={RedoClick}
                        DeployDisabled={DeployDisabled}
                        moreActionsClick = {moreActionsClick}
                        EmulateErr = {EmulateErr}
                        CompanyList={CompanyList}
                        selectedCompany={selectedCompany}

                    />
                    <Diagramm
                        lang={lang}
                        DiagramSaveClicked={DiagramSaveClicked}
                        DiagramDeployClicked={DiagramDeployClicked}
                        EmulateClicked={EmulateClicked}
                        CallBackTreeData={CallBackTreeData}
                        UndoClicked={UndoClicked}
                        RedoClicked={RedoClicked}
                        CallBackDeployDisabled={CallBackDeployDisabled}
                        MoreActionsClicked = {MoreActionsClicked}
                        CallBackEmulateError = {CallBackEmulateError}
                        CompanyList={CompanyList}
                        SelectedCompany={selectedCompany}
                    />
                </div>
            )
        }


    const Trees = () => {
        return (
            <div>
                <Header lang={lang} CompanyList={CompanyList} selectedCompany={selectedCompany} />
                <TreeList lang={lang} CompanyList={CompanyList} selectedCompany={selectedCompany}/>
            </div>
        )
    }


    const DeployRequests = () => {
        return (
            <div>
                <Header lang={lang} CompanyList={CompanyList} selectedCompany={selectedCompany}/>
                <DeployRequest lang={lang} CompanyList={CompanyList} selectedCompany={selectedCompany}/>
            </div>
        )
    }

    const UserManagements = () => {
        return (
            <div>
                <Header lang={lang} CompanyList={CompanyList} selectedCompany={selectedCompany} hidden/>
                <UserManagement lang={lang} CompanyList={CompanyList} selectedCompany={selectedCompany}/>
            </div>
        )
    }

    const Compare = () => {
        return (
            <div>
                <Header
                    lang={lang}
                    CompanyList={CompanyList}
                    selectedCompany={selectedCompany}
                    DeployManageActionsClick={DeployManageActionsClick}
                    DeployManageActionsStat={DeployManageActionsStat}


                />
                <CompareTrees
                    DeployManageActionsClicked={DeployManageActionsClicked}
                    DeployManageActionsStatus = {DeployManageActionsStatus}
                />
            </div>
        )
    }

    const NoPage = () => {
        return (
            <div>
                <Header lang={lang} CompanyList={CompanyList} selectedCompany={selectedCompany} />
                NOPAGE
            </div>
        )
    }


    return (

        <div className="App" >
            <Snackbar open={infoShow} autoHideDuration={10000} onClose={handleInfoClose}
                      TransitionComponent={Fade}
                      key={SlideTransition.name}
            >
                <Alert onClose={handleInfoClose} severity={infoType} sx={{ width: '100%' }}>
                    {
                        infoMessage.split("|").map((message,index) =>
                            <span style={{display: 'block'}} key={index}>{infoMessage.split("|").length > 1 ? index + " - ": ''} {message} </span>
                        )
                    }
                </Alert>
            </Snackbar>
            <BrowserRouter>
                <Routes>

                    <Route path="/diagram/:code" element={Main()} />
                    <Route path="/diagram/:code/:id" element={Main()} />
                    <Route path="/diagram/" element={Main()} />
                    <Route path="/" element={Trees()} />
                    <Route path="/trees" element={Trees()} />
                    <Route path="/deploy-request" element={DeployRequests()} />
                    <Route exact path="/compare/:sourceId/:targetId/" element={Compare()} />
                    <Route path="/compare/:sourceId/:targetId/:drId" element={Compare()} />
                    <Route path="/user-management" element={UserManagements()} />
                    <Route path="*" element={NoPage()} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;